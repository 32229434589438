import { FC } from 'react';

type VKVideoIconProps = {
  fill?: string;
  height?: string;
  width?: string;
};

const VKVideoIcon: FC<VKVideoIconProps> = ({
  fill = 'white',
  width = '100',
  height = '100',
}) => {
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M99.1212 21.6657C98.2425 15.1305 96.4852 10.5448 92.9706 7.03017C89.4559 3.51554 84.8703 1.75818 78.335 0.879482C77.7223 0.7971 77.0926 0.72244 76.4449 0.65478C70.1842 0.000732422 62.2542 0.000732422 52 0.000732422H48C36.989 0.000732422 28.658 0.000732422 22.196 0.810537C15.3769 1.66501 10.6385 3.42111 7.02944 7.03017C3.5148 10.5448 1.75745 15.1305 0.87875 21.6657C0.796367 22.2784 0.721708 22.9082 0.654048 23.5558C0 29.8165 0 37.7465 0 48.0007V52.0007C0 63.0117 0 71.3427 0.809804 77.8047C1.66428 84.6238 3.42037 89.3622 7.02944 92.9713C10.0507 95.9926 13.8634 97.7152 19.0257 98.6975C25.8735 100.001 35.0966 100.001 48 100.001H52C63.315 100.001 71.8 100.001 78.335 99.122C84.8703 98.2433 89.4559 96.4859 92.9706 92.9713C95.9918 89.95 97.7145 86.1373 98.6968 80.9751C100 74.1272 100 64.9041 100 52.0007V48.0007C100 36.6857 100 28.2007 99.1212 21.6657ZM42.53 31.5607C41.3 33.2407 41.3 36.1607 41.3 42.0007V58.0007C41.3 63.8307 41.3 66.7507 42.53 68.4407C43.6 69.9107 45.24 70.8607 47.05 71.0507C49.1216 71.2698 51.6296 69.8225 56.6487 66.9261L70.6952 58.8126C75.6552 55.9447 78.1476 54.5036 78.99 52.6107C79.73 50.9507 79.73 49.0507 78.99 47.3907C78.14 45.4907 75.62 44.0307 70.56 41.1107L56.71 33.1107C51.65 30.1907 49.12 28.7307 47.05 28.9507C45.24 29.1407 43.6 30.0907 42.53 31.5607ZM27.1346 95.6345C25.3587 95.5172 23.7197 95.3623 22.1985 95.1577C16.1065 94.3387 12.5055 92.7905 9.85786 90.1429C7.21022 87.4952 5.66206 83.8942 4.84301 77.8023C4.00849 71.5952 4 63.4275 4 52.0007V48.0007C4 36.5739 4.00849 28.4062 4.84301 22.1992C5.66206 16.1072 7.21022 12.5062 9.85786 9.8586C12.5055 7.21095 16.1065 5.66279 22.1985 4.84374C23.7067 4.64096 25.3308 4.48695 27.0892 4.36999C25.852 5.11662 24.7285 5.99223 23.7 7.02073C16.67 14.0507 16.67 25.3607 16.67 47.9907V51.9907C16.67 74.6207 16.67 85.9307 23.7 92.9607C24.7391 93.9998 25.8794 94.8829 27.1346 95.6345Z'
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default VKVideoIcon;
